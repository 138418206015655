import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import Hero from '../components/bg-img-hero-1';

const Service = (props) => {
  const { heroImage } = props.data;
  const { img1 } = props.data;
  const { oversized } = props.data;
  const { unique } = props.data;
  const { artistic } = props.data;
  return (
    <Layout bodyClass="page-home">
      <SEO
      title="Artists Choice - Wisconsin Framing Company"
      description="Wisconsin Custom Framing - In Person - Or By Mail - Oversize Framing - Shadowbox Framing - And More!"
      />
      <Hero 
      background={heroImage.childImageSharp.fluid} 
      headline={"Artists’ Choice Services"}
      subhead={'Custom Framing, Oversize Framing, Shadowbox Framing, And More!'}
      />
      <div className="container pb-4  pb-md-8 border-top pt-4 ">
      <h2 className="pb-2">Our Custom Framing Services:</h2>
      <div className="tile is-ancestor">
      <div class="tile is-vertical is-parent">
      {/* *** Individual Service *** */}
      <div className="tile box columns is-vcentered my-4">
        <div className="has-text-centered column	is-half">
        <Img className="background is-transparent" fluid={artistic.childImageSharp.fluid} alt='minneapolis wisconsin custom fine art framing company' />
        </div>
        <div className="column is-half">
        <h4 className="is-size-2">Fine Art Framing</h4>
          <div className="my-auto pb-2">Artistically complimenting the work of fine artists takes a direct understanding of the artist and their intent in their artwork.  With our free artist consultation and quoting process, we pride ourselves in cultivating a large repeat business foundation of visual artists in a community as profound as Minneapolis.</div> 
          <div className='is-variable is-2'>
            {/* <div className=''><Link className='button is-medium  my-1 is-fullwidth' to=''>Unique Framing Info.</Link></div> */}
            <div className=''><Link className='button is-medium is-danger my-1 is-fullwidth' to='/get-something-framed'>Free Consultation And Quote</Link></div>
          </div>
        </div>        
      </div>
      {/* *** END Individual Service *** */}

      {/* *** Individual Service *** */}
      <div className="tile box columns is-vcentered my-4">
        <div className="has-text-centered column	is-half">
        <Img className="background is-transparent" fluid={img1.childImageSharp.fluid} alt='minneapolis wisconsin shadowbox framing company'/>
        </div>
        <div className="column is-half">
        <h4 className="is-size-2">Shadowbox Framing</h4>
          <div className="my-auto pb-2"> Shadowboxes work great for: medals, buttons, hockey pucks, baseball bats, tennis rackets, flags, jerseys, jackets, baptismal gowns, tapestries, quilts, jewelry, and anything else you might have.</div> 
          <div className='is-variable is-2'>
            <div className=''><Link className='button is-medium  my-1 is-fullwidth' to=''>Shadowbox Framing Info.</Link></div>
            <div className=''><Link className='button is-medium is-link my-1 is-fullwidth' to='/get-something-framed'>Free Consultation And Quote</Link></div>
          </div>
        </div>        
      </div>
      {/* *** END Individual Service *** */}

      {/* *** Individual Service *** */}
      <div className="box my-4">
        <div>
        <Img className="background is-transparent" fluid={oversized.childImageSharp.fluid} alt='minneapolis, mn oversized custom framing company' />
        </div>

        <div className='columns is-vcentered '>
        
        <div className="column is-half">
        <h4 className="is-size-2">Oversized Framing</h4>
          <div className="my-auto pb-2"> No piece of art is too large for us to frame and no canvas is too big for us to wrap around a stretcher bar.  It takes a tall person to be able to handle large artwork and at 6’2” our master framer, Todd Roepke, is the man for the job.</div> 
        </div>
        <div className='is-variable  is-2 is-half tac'>
            {/* <div className=''><Link className='button is-medium  my-1 is-fullwidth' to=''>Oversized Framing Info.</Link></div> */}
            <div className=''><Link className='button is-medium is-primary my-1 is-fullwidth' to='/get-something-framed'>Free Consultation And Quote</Link></div>
          </div>
        </div>      
      </div>
      {/* *** END Individual Service *** */}

      {/* *** Individual Service *** */}
      <div className="tile box columns is-vcentered my-4">
        <div className="has-text-centered column	is-half">
        <Img className="background is-transparent" fluid={unique.childImageSharp.fluid} alt='minneapolis wisconsin custom framing company' />
        </div>
        <div className="column is-half">
        <h4 className="is-size-2">Unique Framing</h4>
          <div className="my-auto pb-2"> Shadowboxes work great for: medals, buttons, hockey pucks, baseball bats, tennis rackets, flags, jerseys, jackets, baptismal gowns, tapestries, quilts, jewelry, and anything else you might have.</div> 
          <div className='is-variable column is-centered is-2'>
            {/* <div className=''><Link className='button is-medium  my-1 is-fullwidth' to=''>Unique Framing Info.</Link></div> */}
            <div className=''><Link className='button is-medium is-danger my-1 is-fullwidth' to='/get-something-framed'>Free Consultation And Quote</Link></div>
          </div>
        </div>        
      </div>
      {/* *** END Individual Service *** */}
  
      </div>
      </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: file( relativePath: { eq: "wisconsin-framing-company.jpg" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file( relativePath: { eq: "framed-examples/shadowbox/wisconsin-mail-in-sports-memorabilia-company.png" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oversized: file( relativePath: { eq: "framed-examples/oversized/oversized-mail-in-framing-company-wisconsin.png" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    unique: file( relativePath: { eq: "wisconsin-online-framing-company.png" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    artistic: file( relativePath: { eq: "framed-examples/fineArt/fine-art-framing-company-wisconsin.png" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  
`;



export default Service;
